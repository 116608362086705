import * as React from 'react'
import Layout from '/src/components/layout'

import appstoreBadge from '/src/images/apple-app-store-badge.svg'

const SupportAppPage = () => {
	return (
		<Layout pageTitle="Support kring Brfify-appen">

            <p className="intro">Du kommer snabbt och enkelt igång med appen genom inbjudan du får av din fastighetsägare där all information du behöver finns. Eller, om du redan har en e-postadress registrerad i Brfify, genom att logga in med en engångskod som skickas via e-post.</p>
            


            <h2>Kom igång med appen</h2>
            <p>
                <b>1. Ladda ner appen</b><br/>
                Gå in i Apple App Store eller Google Play Store, sök på "Brfify" och ladda ner appen.
            </p>

            <div>
                <a href="https://play.google.com/store/apps/details?id=se.iterik.brfify&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                    <img alt="Ladda ned på Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png" style={{ height: 100, marginTop: 5 }} />
                </a>
                <a href="https://apps.apple.com/se/app/brfify/id1536318130" target="_blank">
                    <img src={appstoreBadge} style={{ height: 77, margin: '11px 10px' }} />
                </a>
                <br/>
            </div>

            <p>
                <br/>
                <b>2. Öppna appen &amp; följ instruktionerna</b><br/>
                <br/>
            </p>

            { /*
            <p>
                <b>2. Öppna appen &amp; logga in</b><br/>
                Öppna appen och logga in på något av sätten, antingen med hjälp av kameran scanna en så kallad QR-kod på inbjudan eller att manuellt skriva in informationen från inbjudan.
            </p>

            <p style={{marginLeft: '10px'}}><u>a) Scanna QR-koden på inbjudan</u><br/>1. Klicka på knappen "Scanna med kamera".<br/>2. Tillåt att appen får använda din kamera (om du får frågan).<br/>3. Håll mobilen/surfplattan framför QR-koden på inbjudan så hittar appen automatiskt QR-koden.</p>

            <p style={{marginLeft: '10px'}}><u>b) Ange ID &amp; hemlighet manuellt</u><br/>1. Klicka på knappen "Ange manuellt".<br/>2. Skriv in det ID och den Hemlighet som står på din inbjudan och klicka på "Logga in".</p>
            <p><b>3. Klart!</b><br/>Nu är appen redo att användas och du behöver inte logga in igen. Du kan nu slänga din inbjudan eftersom den endast går att använda en gång.</p>
            

            <h2>Dela med dig till fler!</h2>
            <p>Inbjudan du får av din brf fungerar av säkerhetsskäl endast en gång. Men du kan själv logga in på fler enheter genom att skapa ny inbjudan på en enhet där du redan är inloggad. Om du inte är inloggad på någon enhet behöver du få en ny inbjudan av din brf.</p>
            <ol style={{marginLeft: '25px'}}>
                <li>Öppna Brfify-appen där du redan är inloggad.</li>
                <li>Gå in på sidan <em>"Inställningar"</em>&nbsp;(kugghjulet längst upp till höger på sidan <em>"Start"</em>).</li>
                <li>Klicka på knappen <em>"Dela åtkomst"</em> och sedan på <em>"Ja"</em> på frågan som kommer upp.</li>
                <li>Nu har du skapat en ny inbjudan som du kan använda för att logga in på en annan enhet, din egen eller någon annans i boendet.</li>
            </ol>
            */ }
            
            

            <h2>Saknar inbjudan?</h2>
            <p>Om du saknar inbjudan eller att den inte fungerar vänligen kontakta din fastighetsägare, det är endast de som kan ge dig åtkomst. Det är även de som kan svara på om någon e-postadress är registerad på dig och i så fall vilken. Om du är inloggad i appen kan du själv se kontaktuppgifter under "Min profil" i menyn.</p>
            

            <h2>Hur ändrar jag e-postadress?</h2>
            <p>Du kan i appen enkelt själv ändra eller lägga till din e-postadress genom att i menyn klicka på "Min profil" och sedan på "Kontaktuppgifter". Här kan du även ändra/lägga till ditt mobilnummer.</p>


            <h2>Ser inte alla funktioner?</h2>
            <p>Det är din fastighetsägare som väljer vilka av alla Brfifys funktioner som är aktiverade. Vid frågor kontakta din fastighetsägare.</p>


		</Layout>
	)
}

export default SupportAppPage